const variables = {
  darkColor: "#2E294E",
  lightColor: "#FFF",
  lightColor2: "#ECEBE4",
  mutedColor1: "#F9F7F7",
  mutedColor2: "#E3E3E3",

  accentColor1: "#1AAF4B",
  accentColor2: "#247BA0",
  accentColor3: "#EE6C4D",
  accentColor4: "#F5A300",

  baseTextColor: "black",
  secondaryTextColor: "#C8C8C8",

  inputHeight: "45px",
  roundedRadius: "30px",

  smallScreen: "520px",
  mediumScreen: "920px",

  bottomNav: "40px",
};

export default variables;
