import { gql } from "@apollo/client";

export const INCREMENT_JOB_VIEW_COUNT_MUTATION = gql`
  mutation INCREMENT_JOB_VIEW_COUNT_MUTATION($id: String!) {
    incrementJobViewCount(id: $id) {
      id
      views
    }
  }
`;

// export const EMAIL_RECOMMENDED_JOBS = gql`
//   mutation EMAIL_RECOMMENDED_JOBS($id: String!) {
//     sendRecommendedEmail(id: $id) {
//       author {
//         name
//       }
//       branch {
//         name
//       }
//   # categories {}
//       compensationType
//       createdAt
//       description
//       disclaimer
//       id
//       location {
//         name
//       }
//       maxCompensation
//       minCompensation
//       perks {
//         name
//       }
//   # skills {}
//       status
//       title
//       type
//     }
//   }
// `
