import React, { useState } from "react";
import Head from "./Head";
import Layout from "@/common/Layout/Layout";
import ApplicationContext from "@/context/applicationContext";
import { systemLanguage } from "@/root/config";
import { EMAIL_RECOMMENDED_JOBS } from '@/graphql/mutations/jobs'
import { useMutation } from '@apollo/client'

const Page = (props) => {
  const [language, setLanguage] = useState(systemLanguage);

  // if (new Date(Date.now()).getDay() === 1) {
  //   console.log('it is friday')
  //   const [sendRecommendedEmail, {data}] = useMutation(EMAIL_RECOMMENDED_JOBS)
  //   sendRecommendedEmail({variables: {id: 'ckll6au75264451528unmyneic'}})
  //   console.log(data)
  // }
  return (
    <div>
      <ApplicationContext.Provider value={{ language, setLanguage }}>
        <Head />
        <Layout admin={props.admin}>{props.children}</Layout>
      </ApplicationContext.Provider>
    </div>
  );
};

export default Page;
